<template>
  <div>
    <h1 style="text-align: center;">
      User Service Agreement of Shenzhen Meiya Industrial Development Co., Ltd
    </h1>
    <p>
      User Service Agreement of Shenzhen Meiya Industrial Development Co.,
      Ltd（hereinafter referred to as "This Agreement"）It is Shenzhen Meiya
      Industrial Development Co., Ltd（hereinafter referred to as "us"）For the
      user（hereinafter referred to as "You"）Service agreements applicable to
      online products and / or services of Shenzhen Meiya Industrial Development
      or Ltd.
    </p>
    <p>
      This Agreement constitutes a prerequisite for your use of products and /
      or services of Shenzhen Meya Industrial Development Co., Ltd，Please read
      the terms of this Agreement carefully before using the products and / or
      services of Shenzhen Meiya Industrial Development Co., Ltd.
    </p>
    <h2>
      First、Service instructions
    </h2>
    <ul>
      <li>
        Under this Agreement, we will resolutely safeguard your legitimate
        rights and interests and provide you with the products and / or services
        of Shenzhen Meiya Industrial Development Co., Ltd. in the following
        ways：

        <ul class="indent">
          <li>
            （1）Introduce the products and / or services of Shenzhen Meiya
            Industrial Development Co., Ltd. and provide corresponding services
            through the official website of Shenzhen Meiya Industrial
            Development Co., Ltd；
          </li>
          <li>
            （2）Provide the products and / or services of Shenzhen Meiya
            Industrial Development Co., Ltd. except for the official website of
            Shenzhen Meiya Industrial Development Co., Ltd. through various
            clients；
          </li>
          <li>
            （3）Any software related to the above two categories of products
            and / or services；
          </li>
          <li>
            （4）Other products and / or services we provide you with Shenzhen
            Meiya Industrial Development Co. from time to time.
          </li>
        </ul>
      </li>
      <li>
        The products and / or services of Shenzhen Meiya Industrial Development
        Co., Ltd. are applicable to adult users over the age of 18.Minors shall
        use the products and / or services of Shenzhen Meiya Industrial
        Development Co., Ltd., accompanied by and under the guidance of their
        guardians.
      </li>
      <li>
        If you do not agree with this policy or any of it, you may immediately
        stop using the relevant services that we provide.To enable real-time
        record control functionality, we will collect your following
        information：
        <ul class="indent">
          <li>WLAN information, device status, etc。</li>
        </ul>
      </li>
    </ul>

    <h2>
      Second、User's rights and obligations
    </h2>
    <ul>
      <li>
        1.You understand and agree that when you use our service and access it
        to the Internet, you are at your own expense for the upstream and
        downstream network communication and traffic expenses incurred by your
        use of the service, which will be charged to you directly by the network
        operator.
      </li>
      <li>
        2.You understand and agree to comply with the Terms and Rules of this
        Agreement and to promptly view and comply with the Terms and Rules
        published and / or updated from time to time.
      </li>
      <li>
        3.You understand and agree that you should comply with the PRC
        Constitution and other laws and regulations during the use of the MPE
        products and / or services.
      </li>
      <li>
        4.You understand and agree that you may not do the following:

        <ul class="indent">
          <li>
            （1）Upload, transmission, spread contains any opposition to the
            basic principles determined by the constitution, endangering
            national security, reveal state secrets, subvert state power,
            destroy national unity, destroy national unity, damage national
            honor and interests, incite national hatred, national
            discrimination, destroy national unity, destroy national religious
            policy, promote cult and feudal superstition, obscene, pornography,
            gambling, violence, murder, terror or abetting crime, insult or
            slander others, Any infringement on the legitimate rights and
            interests of other persons prohibited by laws or administrative
            regulations or other contents disliked by others shall, including
            but not limited to information, materials, text, photos, graphics,
            information or other materials;
          </li>
          <li>
            （2）Upload, transmit, disseminate, send e-mail to our published
            email address or otherwise transmit content not entitled to transmit
            or publish, such as internal information, confidential information,
            patents, trademarks, copyright, trade secrets, or other exclusive
            rights, advertising letters, promotional materials, "spam," and
            software viruses or other computer codes, files and programs that
            restrict any computer software, hardware or communication device
            functions, including but not limited to information, information,
            text, photos, graphics, information or other information;
          </li>
          <li>（3）To harm minors in any way;</li>
          <li>
            （4）Imposing any person or institution, or falsely claiming or
            mistake a person for connection with any person or any agency;
          </li>
          <li>
            （5）Tracking or harass other people in other ways, illegally invade
            other people's network, interfere with the normal function of
            others's network, steal network data and other acts endangering
            network security;
          </li>
          <li>
            （6）To provide others with procedures and tools specially used for
            engaging in activities that endanger network security, interfering
            with the normal functions and protective measures of the network,
            stealing network data, or knowing that others are engaged in
            activities endangering network security, to provide them with
            technical support, advertising promotion, payment and settlement and
            other help;
          </li>
          <li>
            （7）Deliberately or unintentionally violate any relevant Chinese
            laws, regulations, rules, regulations and other regulations with
            legal effect;
          </li>
        </ul>
      </li>
    </ul>
    <h2>
      Third、individual information protection
    </h2>
    <p>
      1.We attach great importance to the protection of user personal
      information (i. e., information that identifies the user independently or
      combined with other information), and when you use the services we
      provide, you agree to collect, store, use, disclose and protect your
      personal information in accordance with our published Privacy Policy.We
      wish to give you the Privacy Policy how we process your personal
      information through the Privacy Policy, so we recommend you read the
      Privacy Policy completely to help you better protect your privacy rights.
    </p>
    <h2>
      Fourth、Change and termination of the agreement
    </h2>
    <ul>
      <li>
        We have the right to change or terminate some or all of the Products /
        Services at any time based on our own business development or other
        reasonable reasons, and we will not be liable for breach of contract
        against you or any third party.
      </li>
      <li>
        We will provide you with the corresponding services and guarantees in
        accordance with the legal requirements and the mature, reliable and safe
        industry programs.
      </li>
      <li>
        If you have any doubts or need to consult other related matters during
        reading the agreement, you can contact us through contact customer
        service or send mail, and we will answer you as soon as possible.
      </li>
    </ul>
  </div>
</template>

<script></script>

<style scoped>
h1 {
  margin-top: 1em;
  font-size: 1.4rem;
}
h2 {
  margin-top: 0.5em;
  font-size: 1.2rem;
}
p {
  margin-top: 0.3em;
}
div {
  padding: 0.8rem;
}
* {
  line-height: 1.5;
}
.indent {
  text-indent: 1rem;
}
</style>
